const flowlyticsEvents = {
  Flowlytics_Reports_Clicked_DataFreshnessCTA: 'Flowlytics_Reports_Clicked_DataFreshnessCTA',
  Flowlytics_Reports_Clicked_ReportsModal: 'Flowlytics_Reports_Clicked_ReportsModal',
  Flowlytics_Reports_Clicked_ReportsNavItem: 'Flowlytics_Reports_Clicked_ReportsNavItem',
  Flowlytics_Reports_Clicked_ReportsButton: 'Flowlytics_Reports_Clicked_ReportsButton',
  Flowlytics_Reports_Clicked_BackToReportsButton: 'Flowlytics_Reports_Clicked_BackToReportsButton',
  Flowlytics_MarketingPage_Clicked_ScheduleDemoButton:
    'Flowlytics_MarketingPage_Clicked_ScheduleDemoButton',
  Flowlytics_MarketingPage_Clicked_LearnMoreButton:
    'Flowlytics_MarketingPage_Clicked_LearnMoreButton',
  Flowlytics_MarketingPage_Hovered: 'Flowlytics_MarketingPage_Hovered',
  Flowlytics_GettingStarted_Clicked_FAQ: 'Flowlytics_GettingStarted_Clicked_FAQ',
  Flowlytics_GettingStarted_Clicked_Section: 'Flowlytics_GettingStarted_Clicked_Section',
  Flowlytics_GettingStarted_Clicked_Video: 'Flowlytics_GettingStarted_Clicked_Video',
  Flowlytics_GettingStarted_Opened_FAQ_Drawer: 'Flowlytics_GettingStarted_Opened_FAQ_Drawer',
  Flowlytics_Native_Analytics_Clicked_Analytics_Navigation_Tab: 'userClickedAnalyticsTab', // *
  Flowcode_Flowlytics_Native_Analytics_Clicked_Code_Details_Analytics_Tab:
    'userClickedCodeDetailsAnalyticsTab', // *
  Flowcode_Flowlytics_Native_Analytics_Clicked_Code_Management_Code_Analytics_Tab:
    'userClickedCodeManagementAnalyticsTab', // *
  Flowcode_Flowlytics_Native_Analytics_Hover_Code_Management_Code_Analytics_Tab:
    'userHoveredOnCodeManagementAnalyticsGraph', //*
  Flowlytics_Native_Analytics_Clicked_Connections_Dropdown:
    'Flowlytics_Native_Analytics_Clicked_Connections_Dropdown',
  Flowlytics_Native_Analytics_Dismissed_Export_Modal:
    'Flowlytics_Native_Analytics_Dismissed_Export_Modal',
  Flowlytics_Native_Analytics_Clicked_Export_Button:
    'Flowlytics_Native_Analytics_Clicked_Export_Button',
  Flowlytics_Native_Analytics_Applied_Filter: 'Flowlytics_Native_Analytics_Applied_Filter',
  Flowlytics_Native_Analytics_Removed_Filter: 'Flowlytics_Native_Analytics_Removed_Filter',
  Flowlytics_Native_Analytics_Cleared_Filters: 'Flowlytics_Native_Analytics_Cleared_Filters',
  Flowlytics_Native_Analytics_Toggled_Show_Org_Assets:
    'Flowlytics_Native_Analytics_Toggled_Show_Org_Assets',
  Flowlytics_Native_Analytics_Dismissed_Filter_Modal:
    'Flowlytics_Native_Analytics_Dismissed_Filter_Modal',
  Flowlytics_Native_Analytics_Opened_Filter_Modal:
    'Flowlytics_Native_Analytics_Opened_Filter_Modal',
  Flowlytics_Native_Analytics_Clicked_Upsell_Gate:
    'Flowlytics_Native_Analytics_Clicked_Upsell_Gate',
  Flowlytics_Native_Analytics_Clicked_Top_Tab: 'Flowlytics_Native_Analytics_Clicked_Top_Tab',
  Flowlytics_Native_Analytics_Hover_Dashboard: 'userHoveredOnDashboardAnalyticsGraph', // *
  Flowlytics_Native_Analytics_Clicked_Folder_Analytics_Tab: 'userClickedFolderAnalyticsTab', // *
  Flowpage_Flowlytics_Native_Analytics_Clicked_Page_Management_Page_Analytics_Tab:
    'Flowpage_Flowlytics_Native_Analytics_Clicked_Page_Management_Page_Analytics_Tab',
  Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsAllTab:
    'Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsAllTab',
  Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast7DaysTab:
    'Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast7DaysTab',
  Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast30DaysTab:
    'Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast30DaysTab',
  Flowlytics_NativeAnalytics_Clicked_WeeklyBreakdownProUpsell:
    'Flowlytics_NativeAnalytics_Clicked_WeeklyBreakdownProUpsell',
  Flowlytics_NativeAnalytics_Clicked_DeviceInsightsProUpsell:
    'Flowlytics_NativeAnalytics_Clicked_DeviceInsightsProUpsell',
  Flowlytics_NativeAnalytics_Clicked_TimeOfDayProUpsell:
    'Flowlytics_NativeAnalytics_Clicked_TimeOfDayProUpsell',
  Flowlytics_NativeAnalytics_Clicked_LearnMoreProUpsellBanner:
    'Flowlytics_NativeAnalytics_Clicked_LearnMoreProUpsellBanner',
  Flowlytics_NativeAnalytics_Clicked_UpgradeProUpsellBanner:
    'Flowlytics_NativeAnalytics_Clicked_UpgradeProUpsellBanner',
  Flowlytics_Reports_Clicked_LearnMoreEnterpriseUpsellBanner:
    'Flowlytics_Reports_Clicked_LearnMoreEnterpriseUpsellBanner',
  Flowlytics_Reports_Clicked_UpgradeEnterpriseUpsellBanner:
    'Flowlytics_Reports_Clicked_UpgradeEnterpriseUpsellBanner',
  Flowlytics_Native_Analytics_Hovered_Analytics_Graph:
    'Flowlytics_Dashboard_Hovered_Analytics_Graph',
  Flowlytics_Native_Analytics_Clicked_Export_Option:
    'Flowlytics_Native_Analytics_Clicked_Export_Option',
  Flowlytics_Native_Analytics_Clicked_Gate: 'Flowlytics_Native_Analytics_Clicked_Gate',

  Flowpage_Flowlytics_NativeAnalytics_Selected_Top_UTM_Tab:
    'Flowpage_Flowlytics_NativeAnalytics_Selected_Top_UTM_Tab',
  Flowlytics_Native_Analytics_Selected_Top_Metrics_Filter:
    'Flowlytics_Native_Analytics_Selected_Top_Metrics_Filter',
  Flowlytics_Native_Analytics_Selected_Gate_Preview:
    'Flowlytics_Native_Analytics_Selected_Gate_Preview',
  Flowlytics_Native_Analytics_Selected_Gate_Upgrade:
    'Flowlytics_Native_Analytics_Selected_Gate_Upgrade',
  Flowlytics_Native_Analytics_Dismissed_Gate_Preview:
    'Flowlytics_Native_Analytics_Dismissed_Gate_Preview',
  Flowlytics_Dashboard_Clicked_TopPerformingCode: 'Flowlytics_Dashboard_Clicked_TopPerformingCode',
  Flowlytics_Dashboard_Clicked_TopPerformingPage: 'Flowlytics_Dashboard_Clicked_TopPerformingPage',
  Flowlytics_Dashboard_Clicked_TopPerformingDestinations:
    'Flowlytics_Dashboard_Clicked_TopPerformingDestinations',
  Flowlytics_Dashboard_Clicked_ViewTopPerformingCode:
    'Flowlytics_Dashboard_Clicked_ViewTopPerformingCode',
  Flowlytics_Dashboard_Clicked_ViewTopPerformingLink:
    'Flowlytics_Dashboard_Clicked_ViewTopPerformingLink',
  Flowlytics_LandingPage_Clicked_ChartCardButton: 'Flowlytics_LandingPage_Clicked_ChartCardButton',
  Flowlytics_LandingPage_Clicked_NoDataCTA: 'Flowlytics_LandingPage_Clicked_NoDataCTA',
  Flowlytics_LandingPage_Mouseover_Chart: 'Flowlytics_LandingPage_Mouseover_Chart',
  Flowlytics_Native_Analytics_Clicked_Pagination_Button:
    'Flowlytics_Native_Analytics_Clicked_Pagination_Button',
  Flowlytics_Desktop_Action_Bar_Clicked_Back_Button:
    'Flowlytics_Desktop_Action_Bar_Clicked_Back_Button',
  Flowlytics_Dashboard_Clicked_Table_Option: 'Flowlytics_Dashboard_Clicked_Table_Option',
  Flowlytics_Native_Analytics_Opened_EnhancedFilterModal:
    'Flowlytics_Native_Analytics_Opened_EnhancedFilterModal',
  Flowlytics_Native_Analytics_Applied_EnhancedFilter:
    'Flowlytics_Native_Analytics_Applied_EnhancedFilter',
  Flowlytics_Native_Analytics_Cleared_EnhancedFilters:
    'Flowlytics_Native_Analytics_Cleared_EnhancedFilters',
  Flowlytics_Actions_Clicked_Schedule_Button: 'Flowlytics_Actions_Clicked_Schedule_Button',
  Flowlytics_Actions_Clicked_Add_Schedule: 'Flowlytics_Actions_Clicked_Add_Schedule',
  Flowlytics_ScheduledReports_Clicked_Schedule: 'Flowlytics_ScheduledReports_Clicked_Schedule',
  Flowlytics_ScheduledReports_Clicked_Add: 'Flowlytics_ScheduledReports_Clicked_Add',
  Flowlytics_ScheduledReports_Changed_Tab: 'Flowlytics_ScheduledReports_Changed_Tab',
  Flowlytics_ScheduledReports_Selected_Frequency: 'Flowlytics_ScheduledReports_Selected_Frequency',
  Flowlytics_ScheduledReports_Selected_Interval: 'Flowlytics_ScheduledReports_Selected_Interval',
  Flowlytics_ScheduledReports_Selected_StartHour: 'Flowlytics_ScheduledReports_Selected_StartHour',
  Flowlytics_ScheduledReports_Selected_EndHour: 'Flowlytics_ScheduledReports_Selected_EndHour',
  Flowlytics_ScheduledReports_Selected_DeliveryType:
    'Flowlytics_ScheduledReports_Selected_DeliveryType',
  Flowlytics_ScheduledReports_Selected_Format: 'Flowlytics_ScheduledReports_Selected_Format',
  Flowlytics_ScheduledReports_Clicked_Cancel: 'Flowlytics_ScheduledReports_Clicked_Cancel',
  Flowlytics_ScheduledReports_Clicked_Pause: 'Flowlytics_ScheduledReports_Clicked_Pause',
  Flowlytics_ScheduledReports_Clicked_Delete: 'Flowlytics_ScheduledReports_Clicked_Delete',
  Flowlytics_ScheduledReports_Selected_AssetFilter:
    'Flowlytics_ScheduledReports_Selected_AssetFilter',
  Flowlytics_ScheduledReports_Selected_FolderFilter:
    'Flowlytics_ScheduledReports_Selected_FolderFilter',
  Flowlytics_ScheduledReports_Selected_TagFilter: 'Flowlytics_ScheduledReports_Selected_TagFilter',
  Flowlytics_ScheduledReports_Selected_TeamFilter:
    'Flowlytics_ScheduledReports_Selected_TeamFilter',
  Flowlytics_ScheduledReports_Selected_DateRange: 'Flowlytics_ScheduledReports_Selected_DateRange',
  Flowlytics_ScheduledReports_Selected_RecurrenceDay:
    'Flowlytics_ScheduledReports_Selected_RecurrenceDay',
  Flowlytics_ScheduledReports_Selected_RecurrenceTime:
    'Flowlytics_ScheduledReports_Selected_RecurrenceTime',
  Flowlytics_ScheduledReports_Created_Schedule: 'Flowlytics_ScheduledReports_Created_Schedule',
  Flowlytics_ScheduledReports_Submitted_Recipients:
    'Flowlytics_ScheduledReports_Submitted_Recipients',
  Flowlytics_Domains_Clicked_SetupPixel: 'Flowlytics_Domains_Clicked_SetupPixel',
  PixelSetup_EnterDomain_Typed_WebsiteDomain: 'PixelSetup_EnterDomain_Typed_WebsiteDomain',
  PixelSetup_EnterDomain_Clicked_NextButton: 'PixelSetup_EnterDomain_Clicked_NextButton',
  PixelSetup_EnterDomain_Clicked_CancelButton: 'PixelSetup_EnterDomain_Clicked_CancelButton',
  PixelSetup_ConversionAction_Selected_MatchType: 'PixelSetup_ConversionAction_Selected_MatchType',
  PixelSetup_ConversionAction_Typed_WebsitePath: 'PixelSetup_ConversionAction_Typed_WebsitePath',
  PixelSetup_ConversionAction_Clicked_Submit: 'PixelSetup_ConversionAction_Clicked_Submit',
  PixelSetup_TagInstall_Typed_Email: 'PixelSetup_TagInstall_Typed_Email',
  PixelSetup_TagInstall_Copied_Snippet: 'PixelSetup_TagInstall_Copied_Snippet',
  PixelSetup_TagInstall_Downloaded_Snippet: 'PixelSetup_TagInstall_Downloaded_Snippet',
  PixelSetup_TagInstall_Clicked_Tab: 'PixelSetup_TagInstall_Clicked_Tab',
  PixelSetup_TagInstall_Clicked_ReturnDashboard: 'PixelSetup_TagInstall_Clicked_ReturnDashboard',
  PixelSetup_Setup_Clicked_Cancel: 'PixelSetup_Setup_Clicked_Cancel',
  PixelSetup_Created_Pixel_Successful: 'PixelSetup_Created_Pixel_Successful',
  PixelManagement_Options_Clicked_Open: 'PixelManagement_Options_Clicked_Open',
  PixelManagement_Options_Clicked_Close: 'PixelManagement_Options_Clicked_Close',
  PixelManagement_Hero_Clicked_StartWithPixelSetup:
    'PixelManagement_Hero_Clicked_StartWithPixelSetup',
  PixelManagement_Hero_Clicked_StartWithConversionActionSetup:
    'PixelManagement_Hero_Clicked_StartWithConversionActionSetup',
  PixelManagement_Header_Clicked_SetupConversionAction:
    'PixelManagement_Header_Clicked_SetupConversionAction',
  PixelManagement_Header_Clicked_SetupUniversalPixel:
    'PixelManagement_Header_Clicked_SetupUniversalPixel',
  PixelManagement_Options_Clicked_DeactivatePixel:
    'PixelManagement_Options_Clicked_DeactivatePixel',
  PixelManagement_DeleteDialog_Clicked_Cancel: 'PixelManagement_DeleteDialog_Clicked_Cancel',
  PixelManagement_DeleteDialog_Clicked_Confirm: 'PixelManagement_DeleteDialog_Clicked_Confirm',
  PixelManagement_DeleteConversionDialog_Clicked_Confirm:
    'PixelManagement_DeleteConversionDialog_Clicked_Confirm',
  PixelManagement_DeleteConversionDialog_Clicked_Cancel:
    'PixelManagement_DeleteConversionDialog_Clicked_Cancel',
  PixelManagement_EmptyState_Clicked_GetStarted: 'PixelManagement_EmptyState_Clicked_GetStarted',
  PixelManagement_EmptyState_Clicked_ReadGuide: 'PixelManagement_EmptyState_Clicked_ReadGuide',
  PixelManagement_Edit_Clicked_PixelOption: 'PixelManagement_Edit_Clicked_PixelOption',
  PixelManagement_Edit_Clicked_AddConversionAction:
    'PixelManagement_Edit_Clicked_AddConversionAction',
  PixelManagement_Edit_Clicked_ConversionOption: 'PixelManagement_Edit_Clicked_ConversionOption',
  Flowlytics_Conversions_Hovered_ConversionsLineChart:
    'flowlytics_conversions_hovered_conversionslinechart',
  Flowlytics_NativeAnalytics_Opened_DateFilter: 'Flowlytics_NativeAnalytics_Opened_DateFilter',
  Flowlytics_Native_Analytics_Clicked_AddConversionAction:
    'Flowlytics_Native_Analytics_Clicked_AddConversionAction',
  Flowlytics_RawEvents_Clicked_Button: 'Flowlytics_RawEvents_Clicked_Button',
  Flowlytics_DataCaps_Clicked_BannerMoreInfo: 'Flowlytics_DataCaps_Clicked_BannerMoreInfo',
  Flowlytics_DataCaps_Clicked_BannerUpgrade: 'Flowlytics_DataCaps_Clicked_BannerUpgrade',
  Flowlytics_DataCaps_Clicked_ModalClose: 'Flowlytics_DataCaps_Clicked_ModalClose',
  Flowlytics_DataCaps_Clicked_ModalUpgrade: 'Flowlytics_DataCaps_Clicked_ModalUpgrade',
  Flowlytics_DataCaps_Clicked_NavDrawerUpgrade: 'Flowlytics_DataCaps_Clicked_NavDrawerUpgrade',
  Flowlytics_DataCaps_Viewed_Banner: 'Flowlytics_DataCaps_Viewed_Banner'
}

// * Old Heap Event names; aliased to match the keyname in Heap. See ticket sc-37310 for details

export default flowlyticsEvents
