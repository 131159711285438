import { AssetFilterValue } from '@dtx-company/inter-app/src/redux/slices/assetFilters'
import { fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import eventNames from '../constants/flowlytics'

const e = {
  Flowlytics_Reports_Clicked_DataFreshnessCTA(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_DataFreshnessCTA)
  },
  Flowlytics_Reports_Clicked_ReportsModal({ item }: { item: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_ReportsModal, { item })
  },
  Flowlytics_Reports_Clicked_ReportsNavItem(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_ReportsNavItem)
  },
  Flowlytics_Reports_Clicked_ReportsButton({ reportName }: { reportName: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_ReportsButton, { reportName })
  },
  Flowlytics_Reports_Clicked_BackToReportsButton(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_BackToReportsButton)
  },
  Flowlytics_MarketingPage_Clicked_ScheduleDemoButton({ section }: { section: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_MarketingPage_Clicked_ScheduleDemoButton, { section })
  },
  Flowlytics_MarketingPage_Clicked_LearnMoreButton({ feature }: { feature: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_MarketingPage_Clicked_LearnMoreButton, { feature })
  },
  Flowlytics_MarketingPage_Hovered({ item }: { item: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_MarketingPage_Hovered, { item })
  },
  Flowlytics_GettingStarted_Clicked_FAQ({ linkName }: { linkName: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_GettingStarted_Clicked_FAQ, { linkName })
  },
  Flowlytics_GettingStarted_Clicked_Section({ section }: { section: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_GettingStarted_Clicked_Section, { section })
  },
  Flowlytics_GettingStarted_Clicked_Video({ videoName }: { videoName: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_GettingStarted_Clicked_Video, { videoName })
  },
  Flowlytics_GettingStarted_Opened_FAQ_Drawer({ open }: { open: boolean }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_GettingStarted_Opened_FAQ_Drawer, { open })
  },
  Flowlytics_Native_Analytics_Dismissed_Export_Modal(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Dismissed_Export_Modal)
  },
  Flowlytics_Native_Analytics_Clicked_Export_Button(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Export_Button)
  },
  Flowlytics_Native_Analytics_Applied_Filter({ type }: { type: AssetFilterValue['type'] }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Applied_Filter, { type })
  },
  Flowlytics_Native_Analytics_Removed_Filter({ type }: { type: AssetFilterValue['type'] }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Removed_Filter, { type })
  },
  Flowlytics_Native_Analytics_Dismissed_Filter_Modal(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Dismissed_Filter_Modal)
  },
  Flowlytics_Native_Analytics_Opened_Filter_Modal(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Opened_Filter_Modal)
  },
  Flowlytics_Native_Analytics_Clicked_Top_Tab({ tab }: { tab: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Top_Tab, { tab })
  },
  Flowlytics_Native_Analytics_Clicked_Analytics_Navigation_Tab(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Analytics_Navigation_Tab)
  },
  Flowcode_Flowlytics_Native_Analytics_Clicked_Code_Details_Analytics_Tab(): void {
    fireAnalyticsEvent(
      eventNames.Flowcode_Flowlytics_Native_Analytics_Clicked_Code_Details_Analytics_Tab
    )
  },
  Flowcode_Flowlytics_Native_Analytics_Clicked_Code_Management_Code_Analytics_Tab(): void {
    fireAnalyticsEvent(
      eventNames.Flowcode_Flowlytics_Native_Analytics_Clicked_Code_Management_Code_Analytics_Tab
    )
  },
  Flowlytics_Native_Analytics_Clicked_Connections_Dropdown({
    dateRange
  }: {
    dateRange: string
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Connections_Dropdown, {
      dateRange
    })
  },
  Flowlytics_Native_Analytics_Clicked_Upsell_Gate({ graph }: { graph: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Upsell_Gate, { graph })
  },
  Flowlytics_Native_Analytics_Clicked_Folder_Analytics_Tab(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Folder_Analytics_Tab)
  },
  Flowpage_Flowlytics_Native_Analytics_Clicked_Page_Management_Page_Analytics_Tab(): void {
    fireAnalyticsEvent(
      eventNames.Flowpage_Flowlytics_Native_Analytics_Clicked_Page_Management_Page_Analytics_Tab
    )
  },
  Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsAllTab(): void {
    fireAnalyticsEvent(eventNames.Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsAllTab)
  },
  Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast7DaysTab(): void {
    fireAnalyticsEvent(eventNames.Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast7DaysTab)
  },
  Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast30DaysTab(): void {
    fireAnalyticsEvent(
      eventNames.Flowpage_Flowlytics_NativeAnalytics_Clicked_AnalyticsLast30DaysTab
    )
  },
  Flowlytics_NativeAnalytics_Clicked_WeeklyBreakdownProUpsell(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_NativeAnalytics_Clicked_WeeklyBreakdownProUpsell)
  },
  Flowlytics_NativeAnalytics_Clicked_DeviceInsightsProUpsell(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_NativeAnalytics_Clicked_DeviceInsightsProUpsell)
  },
  Flowlytics_NativeAnalytics_Clicked_TimeOfDayProUpsell(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_NativeAnalytics_Clicked_TimeOfDayProUpsell)
  },
  Flowlytics_NativeAnalytics_Clicked_LearnMoreProUpsellBanner(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_NativeAnalytics_Clicked_LearnMoreProUpsellBanner)
  },
  Flowlytics_NativeAnalytics_Clicked_UpgradeProUpsellBanner(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_NativeAnalytics_Clicked_UpgradeProUpsellBanner)
  },
  Flowlytics_Reports_Clicked_LearnMoreEnterpriseUpsellBanner(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_LearnMoreEnterpriseUpsellBanner)
  },
  Flowlytics_Reports_Clicked_UpgradeEnterpriseUpsellBanner(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Reports_Clicked_UpgradeEnterpriseUpsellBanner)
  },
  Flowlytics_Native_Analytics_Hovered_Analytics_Graph({
    context,
    graph
  }: {
    context?: string
    graph: string
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Hovered_Analytics_Graph, {
      context,
      graph
    })
  },
  Flowlytics_Native_Analytics_Clicked_Export_Option({
    exportOption,
    path
  }: {
    exportOption: string
    path?: string
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Export_Option, {
      exportOption,
      path
    })
  },
  Flowlytics_Native_Analytics_Cleared_Filters(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Cleared_Filters)
  },
  Flowlytics_Native_Analytics_Toggled_Show_Org_Assets({
    showAllAssets
  }: {
    showAllAssets: boolean
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Toggled_Show_Org_Assets, {
      showAllAssets
    })
  },
  Flowlytics_Native_Analytics_Clicked_Gate({ feature }: { feature: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Gate, { feature })
  },
  Flowpage_Flowlytics_NativeAnalytics_Selected_Top_UTM_Tab({ tab }: { tab: string }): void {
    fireAnalyticsEvent(eventNames.Flowpage_Flowlytics_NativeAnalytics_Selected_Top_UTM_Tab, { tab })
  },
  Flowlytics_Native_Analytics_Selected_Top_Metrics_Filter({ filter }: { filter: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Selected_Top_Metrics_Filter, {
      filter
    })
  },
  Flowlytics_Native_Analytics_Selected_Gate_Preview(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Selected_Gate_Preview)
  },
  Flowlytics_Native_Analytics_Selected_Gate_Upgrade(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Selected_Gate_Upgrade)
  },
  Flowlytics_Native_Analytics_Dismissed_Gate_Preview(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Dismissed_Gate_Preview)
  },
  Flowlytics_Dashboard_Clicked_TopPerformingCode(flowcodeId?: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Dashboard_Clicked_TopPerformingCode, {
      flowcode_id: flowcodeId
    })
  },
  Flowlytics_Dashboard_Clicked_TopPerformingDestinations(destination?: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Dashboard_Clicked_TopPerformingDestinations, {
      destination
    })
  },
  Flowlytics_Dashboard_Clicked_TopPerformingPage(pageId?: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Dashboard_Clicked_TopPerformingPage, {
      page_id: pageId
    })
  },
  Flowlytics_Dashboard_Clicked_ViewTopPerformingCode(flowcodeId?: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Dashboard_Clicked_ViewTopPerformingCode, {
      flowcode_id: flowcodeId
    })
  },
  Flowlytics_Dashboard_Clicked_ViewTopPerformingLink(flowpageId?: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Dashboard_Clicked_ViewTopPerformingCode, {
      flowpage_id: flowpageId
    })
  },
  Flowlytics_LandingPage_Clicked_ChartCardButton(path: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_LandingPage_Clicked_ChartCardButton, {
      path
    })
  },
  Flowlytics_LandingPage_Mouseover_Chart({ chart }: { chart: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_LandingPage_Mouseover_Chart, {
      chart
    })
  },
  Flowlytics_LandingPage_Clicked_NoDataCTA({ chart }: { chart: string }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_LandingPage_Clicked_NoDataCTA, {
      chart
    })
  },
  Flowlytics_Native_Analytics_Clicked_Pagination_Button({
    chart,
    pageNum
  }: {
    chart?: string
    pageNum: number
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_Pagination_Button, {
      chart,
      pageNum
    })
  },
  Flowlytics_Desktop_Action_Bar_Clicked_Back_Button(pathname: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Desktop_Action_Bar_Clicked_Back_Button, { pathname })
  },
  Flowlytics_Dashboard_Clicked_Table_Option({
    table,
    option
  }: {
    table: string
    option: string
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Dashboard_Clicked_Table_Option, { table, option })
  },
  Flowlytics_Native_Analytics_Opened_EnhancedFilterModal(type: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Opened_EnhancedFilterModal, { type })
  },
  Flowlytics_Native_Analytics_Applied_EnhancedFilter({
    type,
    value
  }: {
    type: string
    value: string
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Applied_EnhancedFilter, {
      type,
      value
    })
  },
  Flowlytics_Native_Analytics_Cleared_EnhancedFilters(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Cleared_EnhancedFilters)
  },
  Flowlytics_Actions_Clicked_Schedule_Button(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Actions_Clicked_Schedule_Button)
  },
  Flowlytics_Actions_Clicked_Add_Schedule(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Actions_Clicked_Add_Schedule)
  },
  Flowlytics_ScheduledReports_Clicked_Schedule(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Clicked_Schedule)
  },
  Flowlytics_ScheduledReports_Clicked_Add(buttonType: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Clicked_Add, { buttonType })
  },
  Flowlytics_ScheduledReports_Changed_Tab(tab: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Changed_Tab, { tab })
  },
  Flowlytics_ScheduledReports_Selected_Frequency(frequency: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_Frequency, { frequency })
  },
  Flowlytics_ScheduledReports_Selected_Interval(interval: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_Interval, { interval })
  },
  Flowlytics_ScheduledReports_Selected_StartHour(time: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_StartHour, { time })
  },
  Flowlytics_ScheduledReports_Selected_EndHour(time: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_EndHour, { time })
  },
  Flowlytics_ScheduledReports_Selected_DeliveryType(deliveryType: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_DeliveryType, {
      deliveryType
    })
  },
  Flowlytics_ScheduledReports_Selected_Format(format: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_Format, { format })
  },
  Flowlytics_ScheduledReports_Clicked_Cancel(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Clicked_Cancel)
  },
  Flowlytics_ScheduledReports_Clicked_Pause({
    scheduleId,
    isPaused
  }: {
    scheduleId: string
    isPaused: boolean
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Clicked_Pause, {
      scheduleId,
      isPaused
    })
  },
  Flowlytics_ScheduledReports_Clicked_Delete(scheduleId: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Clicked_Delete, { scheduleId })
  },
  Flowlytics_ScheduledReports_Selected_AssetFilter(assetIds: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_AssetFilter, { assetIds })
  },
  Flowlytics_ScheduledReports_Selected_FolderFilter(folderIds: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_FolderFilter, { folderIds })
  },
  Flowlytics_ScheduledReports_Selected_TagFilter(tagNames: string | undefined): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_TagFilter, { tagNames })
  },
  Flowlytics_ScheduledReports_Selected_TeamFilter(teamIds: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_TeamFilter, { teamIds })
  },
  Flowlytics_ScheduledReports_Selected_DateRange({
    startDate,
    endDate
  }: {
    startDate: string | undefined
    endDate: string | undefined
  }): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_DateRange, {
      startDate,
      endDate
    })
  },
  Flowlytics_ScheduledReports_Selected_RecurrenceDay(day: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_RecurrenceDay, { day })
  },
  Flowlytics_ScheduledReports_Selected_RecurrenceTime(time: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Selected_RecurrenceTime, { time })
  },
  Flowlytics_ScheduledReports_Created_Schedule(reportName: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Created_Schedule, { reportName })
  },
  Flowlytics_ScheduledReports_Submitted_Recipients(recipients: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_ScheduledReports_Submitted_Recipients, { recipients })
  },

  // Pixel Wizard Events

  Flowlytics_Domains_Clicked_SetupPixel(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Domains_Clicked_SetupPixel)
  },
  PixelSetup_EnterDomain_Clicked_NextButton(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_EnterDomain_Clicked_NextButton)
  },
  PixelSetup_EnterDomain_Clicked_CancelButton(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_EnterDomain_Clicked_CancelButton)
  },
  PixelSetup_EnterDomain_Typed_WebsiteDomain(domain: string): void {
    fireAnalyticsEvent(eventNames.PixelSetup_EnterDomain_Typed_WebsiteDomain, { domain })
  },
  PixelSetup_ConversionAction_Selected_MatchType(type: string): void {
    fireAnalyticsEvent(eventNames.PixelSetup_ConversionAction_Selected_MatchType, { type })
  },
  PixelSetup_ConversionAction_Typed_WebsitePath(path: string): void {
    fireAnalyticsEvent(eventNames.PixelSetup_ConversionAction_Typed_WebsitePath, { path })
  },
  PixelSetup_ConversionAction_Clicked_Submit(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_ConversionAction_Clicked_Submit)
  },
  PixelSetup_TagInstall_Copied_Snippet(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_TagInstall_Copied_Snippet)
  },
  PixelSetup_TagInstall_Downloaded_Snippet(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_TagInstall_Downloaded_Snippet)
  },
  PixelSetup_TagInstall_Clicked_Tab(tab: string): void {
    fireAnalyticsEvent(eventNames.PixelSetup_TagInstall_Clicked_Tab, { tab })
  },
  PixelSetup_TagInstall_Clicked_ReturnDashboard(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_TagInstall_Clicked_ReturnDashboard)
  },
  PixelSetup_Setup_Clicked_Cancel(): void {
    fireAnalyticsEvent(eventNames.PixelSetup_Setup_Clicked_Cancel)
  },
  PixelSetup_Created_Pixel_Successful({
    appId,
    hasConversionAction
  }: {
    appId: string
    hasConversionAction: boolean
  }): void {
    fireAnalyticsEvent(eventNames.PixelSetup_Created_Pixel_Successful, {
      appId,
      action: hasConversionAction
    })
  },
  PixelManagement_Hero_Clicked_StartWithPixelSetup(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Hero_Clicked_StartWithPixelSetup)
  },
  PixelManagement_Hero_Clicked_StartWithConversionActionSetup(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Hero_Clicked_StartWithConversionActionSetup)
  },
  PixelManagement_Header_Clicked_SetupConversionAction(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Header_Clicked_SetupConversionAction)
  },
  PixelManagement_Header_Clicked_SetupUniversalPixel(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Header_Clicked_SetupUniversalPixel)
  },
  PixelManagement_Options_Clicked_Open(appId: string): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Options_Clicked_Open, { appId })
  },
  PixelManagement_Options_Clicked_Close(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Options_Clicked_Close)
  },
  PixelManagement_Options_Clicked_DeactivatePixel(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Options_Clicked_DeactivatePixel)
  },
  PixelManagement_DeleteDialog_Clicked_Cancel(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_DeleteDialog_Clicked_Cancel)
  },
  PixelManagement_DeleteDialog_Clicked_Confirm(appId?: string): void {
    fireAnalyticsEvent(eventNames.PixelManagement_DeleteDialog_Clicked_Confirm, { appId })
  },
  PixelManagement_DeleteConversionDialog_Clicked_Cancel(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_DeleteConversionDialog_Clicked_Cancel)
  },
  PixelManagement_DeleteConversionDialog_Clicked_Confirm(appId?: string): void {
    fireAnalyticsEvent(eventNames.PixelManagement_DeleteConversionDialog_Clicked_Confirm, { appId })
  },
  PixelManagement_EmptyState_Clicked_GetStarted(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_EmptyState_Clicked_GetStarted)
  },
  PixelManagement_EmptyState_Clicked_ReadGuide(): void {
    fireAnalyticsEvent(eventNames.PixelManagement_EmptyState_Clicked_ReadGuide)
  },
  PixelManagement_Edit_Clicked_PixelOption({
    option,
    appId
  }: {
    option?: string
    appId?: string
  }): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Edit_Clicked_PixelOption, { option, appId })
  },
  PixelManagement_Edit_Clicked_AddConversionAction(appId: string): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Edit_Clicked_AddConversionAction, { appId })
  },
  PixelManagement_Edit_Clicked_ConversionOption({
    option,
    appId
  }: {
    option?: string
    appId?: string
  }): void {
    fireAnalyticsEvent(eventNames.PixelManagement_Edit_Clicked_ConversionOption, { option, appId })
  },
  Flowlytics_Conversions_Hovered_ConversionsLineChart(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Conversions_Hovered_ConversionsLineChart)
  },
  Flowlytics_NativeAnalytics_Opened_DateFilter(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_NativeAnalytics_Opened_DateFilter)
  },
  Flowlytics_Native_Analytics_Clicked_AddConversionAction(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_Native_Analytics_Clicked_AddConversionAction)
  },
  Flowlytics_RawEvents_Clicked_Button(name: string): void {
    fireAnalyticsEvent(eventNames.Flowlytics_RawEvents_Clicked_Button, { name })
  },
  Flowlytics_DataCaps_Clicked_BannerMoreInfo(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_DataCaps_Clicked_BannerMoreInfo)
  },
  Flowlytics_DataCaps_Clicked_BannerUpgrade(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_DataCaps_Clicked_BannerUpgrade)
  },
  Flowlytics_DataCaps_Clicked_ModalClose(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_DataCaps_Clicked_ModalClose)
  },
  Flowlytics_DataCaps_Clicked_ModalUpgrade(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_DataCaps_Clicked_ModalUpgrade)
  },
  Flowlytics_DataCaps_Clicked_NavDrawerUpgrade(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_DataCaps_Clicked_NavDrawerUpgrade)
  },
  Flowlytics_DataCaps_Viewed_Banner(): void {
    fireAnalyticsEvent(eventNames.Flowlytics_DataCaps_Viewed_Banner)
  }
}

export default e
