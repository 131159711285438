export default {
  userClickedContactUsCalendlyLink: 'userClickedContactUsCalendlyLink',
  userClickedFAQAccordionDrawer: 'userClickedFAQAccordionDrawer',
  userClickedRequestDemoLink: 'userClickedRequestDemoLink',
  Core_ContactSales_Submitted_Form: 'Core_ContactSales_Submitted_Form',
  Core_FlowcodeOrFlowpage_Clicked_WhiteLabelDomainDropdownLocked:
    'Core_FlowcodeOrFlowpage_Clicked_WhiteLabelDomainDropdownLocked',
  Core_FlowcodeOrFlowpage_Clicked_GeolocationLock:
    'Core_FlowcodeOrFlowpage_Clicked_GeolocationLock',
  Core_UseCasePage_Toggled_AccordionItem: 'Core_UseCasePage_Toggled_AccordionItem',
  Core_MultiPages_Toggled_FaqAccordion: 'Core_MultiPages_Toggled_FaqAccordion'
}
