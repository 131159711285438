export enum CRM {
  FLOWPAGE = 'Flowpage',
  GOOGLESHEETS = 'Google Sheets',
  MAILCHIMP = 'Mailchimp',
  ZAPIER = 'Zapier',
  KLAVIYO = 'Klaviyo',
  SALESFORCE = 'Salesforce',
  HUBSPOT = 'Hubspot'
}

export enum CrmThirdParty {
  MAILCHIMP,
  ZAPIER,
  GOOGLESHEETS,
  KLAVIYO,
  HUBSPOT
}

export enum CRMFreeLabels {
  FLOWPAGE = 'Flowpage'
}

export enum CRMProLabels {
  GOOGLESHEETS = 'Google Sheets',
  MAILCHIMP = 'Mailchimp'
}

export enum CRMProPlusLabels {
  ZAPIER = 'Zapier',
  KLAVIYO = 'Klaviyo',
  SALESFORCE = 'Salesforce'
}

export enum CRMProFlexLabels {
  HUBSPOT = 'Hubspot'
}

export type CRMLabels = CRMFreeLabels | CRMProLabels | CRMProPlusLabels | CRMProFlexLabels
export const CRMLabels = {
  ...CRMFreeLabels,
  ...CRMProLabels,
  ...CRMProPlusLabels,
  ...CRMProFlexLabels
}
