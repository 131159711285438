import { fireGtmEvent, fireHeapEvent, getDrawerAction } from '../helpers'
import eventNames from '../constants/miscellaneous'

const e = {
  userClickedContactUsCalendlyLink(): void {
    const { userClickedContactUsCalendlyLink } = eventNames
    fireHeapEvent(userClickedContactUsCalendlyLink)
  },
  userClickedContactUsCalendlyLinkOnTvPage(sectionId?: string, isLoggedIn?: boolean): void {
    const { userClickedContactUsCalendlyLink } = eventNames
    fireHeapEvent(userClickedContactUsCalendlyLink, { sectionId, isLoggedIn })
    fireGtmEvent(userClickedContactUsCalendlyLink)
  },
  userClickedRequestDemoLink(): void {
    const { userClickedRequestDemoLink } = eventNames
    fireHeapEvent(userClickedRequestDemoLink)
  },
  userClickedFAQAccordionDrawer(drawer: string | null, opened: boolean): void {
    const { userClickedFAQAccordionDrawer } = eventNames
    fireHeapEvent(userClickedFAQAccordionDrawer, {
      drawer,
      opened: getDrawerAction(opened)
    })
  },
  Core_ContactSales_Submitted_Form(email?: string | string[]): void {
    fireHeapEvent(eventNames.Core_ContactSales_Submitted_Form, { email })
  },
  Core_FlowcodeOrFlowpage_Clicked_WhiteLabelDomainDropdownLocked(): void {
    fireHeapEvent(eventNames.Core_FlowcodeOrFlowpage_Clicked_WhiteLabelDomainDropdownLocked)
  },
  Core_FlowcodeOrFlowpage_Clicked_GeolocationLock(): void {
    fireHeapEvent(eventNames.Core_FlowcodeOrFlowpage_Clicked_WhiteLabelDomainDropdownLocked)
  },
  /** Event fired when a user toggle expand/collapses an item in an accordion in a use-case page */
  Core_UseCasePage_Toggled_AccordionItem(itemTitle: string, expanded: boolean): void {
    fireHeapEvent(eventNames.Core_UseCasePage_Toggled_AccordionItem, {
      title: itemTitle,
      action: expanded ? 'expanded' : 'collapsed'
    })
  },
  Core_MultiplePages_Toggled_FaqAccordion(questionTitle: string, expanded: boolean): void {
    fireHeapEvent(eventNames.Core_MultiPages_Toggled_FaqAccordion, {
      questionTitle,
      action: expanded ? 'expanded' : 'collapsed'
    })
  }
}

export default e
